import { styled } from "@mui/material/styles";

export const MarketPlaceSection = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(6),
    },
}));
