import { SvgIconProps } from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import { ComponentType, ReactNode, HTMLAttributes } from "react";

import { makeStyles } from "../style/makeStyles";

export type LabelValueSize = "small" | "normal";

export type LabelValueProps = {
    Icon?: ComponentType<SvgIconProps>;
    label?: ReactNode;
    spacing?: "normal" | "medium";
    size?: LabelValueSize;
} & HTMLAttributes<HTMLDivElement>;

export const Wrapper = styled("div")(({ theme }) => ({
    "--vertical-gap": theme.spacing(0.25),
    "--horizontal-gap": theme.spacing(2),
    "--content-fontSize": "1rem",
    fontFamily: theme.typography.fontFamily,
    display: "inline-grid",
    gridTemplateAreas: '"label" "content"',
    gridTemplateRows: "min-content auto",
    gridTemplateColumns: "100%",
    gap: "var(--vertical-gap) var(--horizontal-gap)",
    '& [data-ref="label-value-icon"]': {
        gridArea: "icon",
        alignSelf: "top",
    },
    '&[data-spacing="medium"]': {
        "--vertical-gap": theme.spacing(0.5),
        "--horizontal-gap": theme.spacing(3),
    },
    '&&[data-with-icon="true"]': {
        gridTemplate: '"icon label" min-content "icon content" auto / min-content auto',
    },
    '&&[data-with-icon="false"]': {
        "--horizontal-gap": "0px",
    },
    '&[data-size="small"]': {
        "--horizontal-gap": theme.spacing(1),
        "--content-fontSize": "0.875rem",
    },
    '&[data-with-label="false"]': {
        gridTemplateRows: "auto",
        gridTemplateAreas: '"icon content"',
    },
}));

export const Content = styled("span")({
    gridArea: "content",
    alignSelf: "center",
    fontSize: "var(--content-fontSize)",
    lineHeight: 1.4,
});

export const Label = styled("span")(({ theme }) => ({
    gridArea: "label",
    fontSize: "0.75rem",
    lineHeight: 1.2,
    color: theme.palette.text.secondary,
}));

const useStyles = makeStyles()({
    icon: {
        gridArea: "icon",
        alignSelf: "top",
    },
});

export function LabelValue({ Icon, label, size, spacing, children, ...props }: LabelValueProps) {
    const { classes } = useStyles();
    return (
        <Wrapper {...props} data-spacing={spacing} data-with-icon={!!Icon} data-size={size} data-with-label={!!label}>
            {Icon ? <Icon color="primary" data-ref="label-value-icon" className={classes.icon} /> : undefined}
            {label ? <Label>{label}</Label> : undefined}
            <Content>{children}</Content>
        </Wrapper>
    );
}
