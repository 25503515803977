import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { ForwardedRef, forwardRef, MutableRefObject, ReactNode, UIEventHandler, useRef } from "react";

import { ScrollableStateOptions, useScrollableState } from "../hooks/useScrollableState";
import { MainColumnVariableMargin } from "./MainColumnWrapper";

const Wrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexWrap: "nowrap",
    gap: theme.spacing(1),
    width: "100%",
    position: "relative",
    "--scrollable-button-background": theme.palette.common.white,
    "--scrollable-button-color": theme.palette.text.primary,
    '&[data-variant="primary"]': {
        "--scrollable-button-background": theme.palette.primary.main,
        "--scrollable-button-color": theme.palette.common.white,
    },
    '&[data-mode="full-width"]': {
        '[data-ref="container"]': {
            scrollSnapType: "none",
            marginLeft: `calc(${MainColumnVariableMargin.reference} * -1)`,
            marginRight: `calc(${MainColumnVariableMargin.reference} * -1)`,
        },
        '[data-type="back"]': {
            left: `calc(${MainColumnVariableMargin.reference} * -1)`,
        },
        '[data-type="forward"]': {
            right: `calc(${MainColumnVariableMargin.reference} * -1)`,
        },
        '[data-ref="scrollable-item"]': {
            "&:first-child": {
                marginLeft: MainColumnVariableMargin.reference,
            },
            "&:last-child": {
                marginRight: MainColumnVariableMargin.reference,
            },
        },
    },
}));

const Container = styled("div")(({ theme }) => ({
    overflowX: "scroll",
    scrollSnapType: "x mandatory",
    display: "flex",
    flexWrap: "nowrap",
    gap: theme.airiness,

    "&::-webkit-scrollbar": {
        display: "none",
    },
}));

const Button = styled(IconButton)({
    color: "var(--scrollable-button-color)",
    "&:before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        backgroundColor: "var(--scrollable-button-background)",
        opacity: 0.8,
        zIndex: -1,
    },
});

const ButtonWrapper = styled("div")(({ theme }) => ({
    "--background-direction": "to left",
    top: "50%",
    transform: "translateY(-50%)",
    position: "absolute",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    zIndex: 500,
    opacity: 1,
    visibility: "visible",
    transition: theme.transitions.create(["opacity", "visibility"], { duration: theme.transitions.duration.short }),
    '&[data-is-visible="false"]': {
        display: "none",
    },
    '&[data-is-available="false"]': {
        opacity: 0,
        visibility: "hidden",
    },
    '&[data-type="forward"]': {
        "--background-direction": "to right",
        right: 0,
    },
    "@media (pointer: coarse)": {
        display: "none",
    },
}));

export type ScrollableContainerProps = {
    children?: ReactNode;
    gap?: number | string;
    mode?: "normal" | "full-width";
    variant?: "light" | "primary";
    onScroll?: UIEventHandler<HTMLDivElement>;
} & ScrollableStateOptions;

type ScrollButtonProps = {
    type: "back" | "forward";
    onClick: ButtonProps["onClick"];
    isVisible: boolean;
    isAvailable: boolean;
};

function ScrollButton({ type, onClick, isAvailable, isVisible }: ScrollButtonProps) {
    return (
        <ButtonWrapper data-type={type} data-is-visible={isVisible} data-is-available={isAvailable}>
            <Button disabled={!isAvailable} size="large" onClick={onClick}>
                {type === "back" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </Button>
        </ButtonWrapper>
    );
}

// eslint-disable-next-line react/display-name
export const ScrollableContainer = forwardRef(
    (
        { children, slideMinWidth, variant = "light", gap, mode, onScroll, ...props }: ScrollableContainerProps,
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        const scrollableContainerRef = useRef<HTMLElement | null>(null);
        const scrollable = useScrollableState(scrollableContainerRef, {
            slideMinWidth,
        });

        return (
            <Wrapper data-variant={variant} data-mode={mode} ref={ref} {...props}>
                <ScrollButton
                    type="back"
                    isVisible={scrollable.isAvailable}
                    isAvailable={scrollable.isBackAvailable}
                    onClick={(e) => {
                        e.stopPropagation();
                        scrollable.back();
                    }}
                />
                <Container
                    data-ref="container"
                    style={{ gap }}
                    onScroll={onScroll}
                    ref={scrollableContainerRef as MutableRefObject<HTMLDivElement | null>}
                >
                    {children}
                </Container>
                <ScrollButton
                    type="forward"
                    isVisible={scrollable.isAvailable}
                    isAvailable={scrollable.isForwardAvailable}
                    onClick={(e) => {
                        e.stopPropagation();
                        scrollable.forward();
                    }}
                />
            </Wrapper>
        );
    }
);
