import EventBusyIcon from "@mui/icons-material/EventBusy";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Box, SvgIconProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useLanguage } from "../../hooks";
import { useProductTranslation } from "../../hooks/useProductTranslation";
import { formatDurationFromString } from "../../utils/formatters/formatDuration";

type ProductInfoTagsV2Props = {
    hasFreeCancellation?: boolean;
    instantConfirmation?: boolean;
    minDuration?: string;
    maxDuration?: string;
};

export const ProductInfoTagsV2 = (props: ProductInfoTagsV2Props) => {
    const language = useLanguage();
    const [t] = useProductTranslation();

    const { hasFreeCancellation, instantConfirmation, minDuration, maxDuration } = props;
    const duration = formatDurationFromString(language, minDuration ?? undefined, maxDuration ?? undefined);
    const tags = [
        duration ? { label: duration, icon: ScheduleIcon } : undefined,
        instantConfirmation ? { label: t("label.instantConfirmation"), icon: FlashOnIcon } : undefined,
        hasFreeCancellation ? { label: t("label.freeCancellation"), icon: EventBusyIcon } : undefined,
    ].filter(Boolean);

    return (
        <Box display="flex" flexDirection="column" gap={0.25}>
            {tags.map((tag, index) => (
                <ProductTag key={index} label={tag?.label} Icon={tag?.icon} />
            ))}
        </Box>
    );
};

type ProductTagProps = {
    label?: string;
    Icon?: React.ComponentType<SvgIconProps>;
};

const ProductTag = (props: ProductTagProps) => {
    const { Icon, label } = props;
    if (!label || !Icon) return null;

    return (
        <Box display="flex" alignItems="center" gap={0.5}>
            <Icon color="primary" fontSize="small" />
            <ProductTagLabel>{label}</ProductTagLabel>
        </Box>
    );
};

const ProductTagLabel = styled("span")(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.text.secondary,
}));
