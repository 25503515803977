import StarIcon from "@mui/icons-material/Star";
import { Box, Rating } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Typography } from "../../components/Typography";

export const ReviewWrapper = styled("div")(({ theme }) => ({
    display: "inline",
    alignItems: "center",
    gap: theme.spacing(0.5),
}));

export const Star = styled(StarIcon)(({ theme }) => ({
    height: 12,
    width: 12,
    fill: "#F2C94C",
    position: "relative",
    top: -1,
    verticalAlign: "middle",
    marginRight: theme.spacing(0.5),
}));

export const ReviewRating = styled(Typography)({
    display: "inline-flex",
    whiteSpace: "nowrap",
});

export const getReviewRating = (reviewRating: number, reviewCount?: number) => {
    if (!reviewCount) return reviewRating.toFixed(1);

    return `${reviewRating.toFixed(1)} (${reviewCount})`;
};

type ProductReviewsInfoV2Props = {
    reviewRating: number;
    reviewCount?: number;
    withCount?: boolean;
};

export const ProductReviewsInfoV2 = (props: ProductReviewsInfoV2Props) => {
    const { reviewRating, reviewCount } = props;
    const review = getReviewRating(reviewRating, reviewCount);

    return (
        <ReviewWrapper>
            <Star data-star-icon="true" />
            <ReviewRating data-review-rating="true" component="span" variant="body" size="small">
                {review}
            </ReviewRating>
        </ReviewWrapper>
    );
};

type ProductRatingCompactProps = Pick<ProductReviewsInfoV2Props, "reviewRating">;

export const ProductRatingCompact = (props: ProductRatingCompactProps) => {
    const { reviewRating } = props;
    const review = reviewRating.toFixed(1);

    return (
        <ReviewRatingWrapper gap={0.5} display="flex" alignItems="center">
            <StarIcon color="primary" fontSize="inherit" />
            <ReviewRatingLabel>{review}</ReviewRatingLabel>
        </ReviewRatingWrapper>
    );
};

export const ProductRating = (props: ProductReviewsInfoV2Props) => {
    const { reviewRating, reviewCount, withCount = true } = props;

    if (!reviewRating) return null;

    const review = getReviewRating(reviewRating, reviewCount);

    return (
        <Box gap={0.5} display="flex" alignItems="center">
            <RatingStyled
                name="read-only"
                value={reviewRating}
                precision={0.5}
                readOnly
                icon={<StarIcon fontSize="inherit" color="primary" />}
            />
            {withCount && <ReviewRatingLabelStyled>{review}</ReviewRatingLabelStyled>}
        </Box>
    );
};

const ReviewRatingWrapper = styled(Box)({
    fontSize: 16,
});

const RatingStyled = styled(Rating)({
    fontSize: 16,
});

const ReviewRatingLabel = styled("span")(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
}));

const ReviewRatingLabelStyled = styled(ReviewRatingLabel)(({ theme }) => ({
    color: theme.palette.text.secondary,
    marginBlockStart: "0.1em",
}));
