import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ForwardedRef, forwardRef } from "react";

import { CSSVariable } from "../utils/CSSVariable";

/**
 * To apply variant correctly please follow this guide: https://m3.material.io/styles/typography/applying-type
 */
export type TypographyVariant = "display" | "headline" | "title" | "body" | "label";
export type TypographySize = "small" | "medium" | "large";
export type TypographyColor = "dark" | "light" | "primary" | "secondary" | "subtle" | "error" | "warning" | "inherit";
export type TypographyElementType = "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "span" | "li";
type TypographyStyle = {
    lineHeight: number;
    fontWeight: number;
    fontSize: number;
    scaleElement: number;
    letterSpacing?: number;
};
type TypographyFontWeight = "light" | "medium" | "bold";

interface Props {
    children?: React.ReactNode;
    variant?: TypographyVariant;
    size?: TypographySize | "inherit";
    color?: TypographyColor;
    fontWeight?: TypographyFontWeight;
    isResponsive?: boolean;
    component?: TypographyElementType;
    lineClamp?: number;
}

const TYPOGRAPHY_LINE_CLAMP = new CSSVariable("--typography-line-clamp");

export type TypographyProps<C extends React.ElementType = "p"> = Props &
    Omit<React.ComponentPropsWithoutRef<C>, keyof Props>;

/**
 * Typography Component that follows Material Design 3: https://m3.material.io/styles/typography
 *
 * To apply variant correctly please follow this guide: https://m3.material.io/styles/typography/applying-type
 */
export const Typography = forwardRef(function TypographyInternal<C extends React.ElementType = "p">(
    {
        variant = "body",
        size = "medium",
        isResponsive,
        fontWeight,
        color,
        component,
        children,
        lineClamp,
        ...other
    }: TypographyProps<C>,
    ref: ForwardedRef<HTMLElement>
) {
    const as = component ?? VARIANT_ELEMENT_TYPE_MAPPING[variant];
    const finalStyle = { ...other.style, ...(lineClamp && { [TYPOGRAPHY_LINE_CLAMP.toString()]: lineClamp }) };
    return (
        <Wrapper
            as={as}
            data-variant={variant}
            data-size={size}
            data-color={color}
            data-font-weight={fontWeight}
            data-is-responsive={isResponsive}
            data-line-clamp={lineClamp}
            style={finalStyle}
            {...other}
            ref={ref as any}
        >
            {children}
        </Wrapper>
    );
});

// Keep the semantic meaning of html tags for crawlers and SEO
const VARIANT_ELEMENT_TYPE_MAPPING: Record<TypographyVariant, TypographyElementType> = {
    display: "h1",
    headline: "h2",
    title: "h3",
    body: "p",
    label: "span",
};

const FONT_WEIGHTS = {
    light: 300,
    medium: 400,
    bold: 600,
};

// As recommended by M3
// https://m3.material.io/styles/typography/type-scale-tokens#d74b73c2-ac5d-43c5-93b3-088a2f67723d
const VARIANT_FONT_WEIGHT_MAPPING: Record<TypographyVariant, number> = {
    display: FONT_WEIGHTS.medium,
    headline: FONT_WEIGHTS.medium,
    title: FONT_WEIGHTS.medium,
    body: FONT_WEIGHTS.medium,
    label: FONT_WEIGHTS.bold,
};

// The following are recommended "subtle" typescales good for both mobile and desktop
// https://typescale.com/
const TYPESCALES = {
    MINOR_SECOND: 1.067,
    MAJOR_SECOND: 1.125,
    MINOR_THIRD: 1.2,
} as const;

// Major Second Typescale as recommended by material design 3: https://m3.material.io/styles/typography/type-scale-tokens#2a57c8f0-d45b-470a-984f-eb4f89f425fa
const TYPESCALE = TYPESCALES.MAJOR_SECOND;
const MIN_BASE_FONT_SIZE = 14;
// M3 uses 14 as it's key base size: https://m3.material.io/styles/typography/type-scale-tokens
const MAX_BASE_FONT_SIZE = 16;

const getTypeScaleElement = (element: number, baseFont: number) => {
    return baseFont * Math.pow(TYPESCALE, element);
};

// Below we are calculating the constants of a linear equation for a fluid responsive font size
// https://www.smashingmagazine.com/2022/01/modern-fluid-typography-css-clamp/
const getResponsiveFontSizeStyle = (theme: Theme, element: number) => {
    const minFontSize = getTypeScaleElement(element, MIN_BASE_FONT_SIZE);
    const maxFontSize = getTypeScaleElement(element, MAX_BASE_FONT_SIZE);
    const minVWSize = theme.breakpoints.values.sm;
    const maxVWSize = theme.breakpoints.values.lg;

    // y =  m * x + c
    const m = (maxFontSize - minFontSize) / (maxVWSize - minVWSize);
    const c = maxFontSize - m * maxVWSize;

    return {
        fontSize: [`${maxFontSize}px`, `clamp(${minFontSize}px, ${100 * m}vw + ${c}px, ${maxFontSize}px)`],
    };
};

const getFontWeightStyle = (fontWeight: number) => {
    return {
        "&[data-font-weight='bold']": {
            fontWeight: FONT_WEIGHTS.bold,
        },
        "&[data-font-weight='medium']": {
            fontWeight: FONT_WEIGHTS.medium,
        },
        '&[data-font-weight="light"]': {
            fontWeight: FONT_WEIGHTS.light,
        },
        fontWeight,
    };
};

// lineHeight, scaleElement, letterSpacing taken from M3 docs: https://m3.material.io/styles/typography/type-scale-tokens#d74b73c2-ac5d-43c5-93b3-088a2f67723d
const styleConfig: Record<TypographyVariant, Record<TypographySize, TypographyStyle>> = {
    display: {
        large: {
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.display,
            lineHeight: 1.12,
            scaleElement: 10,
            fontSize: 57,
        },
        medium: {
            lineHeight: 1.16,
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.display,
            scaleElement: 9,
            fontSize: 45,
        },
        small: {
            lineHeight: 1.22,
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.display,
            scaleElement: 8,
            fontSize: 36,
        },
    },
    headline: {
        large: {
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.headline,
            lineHeight: 1.25,
            scaleElement: 7,
            fontSize: 32,
        },
        medium: {
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.headline,
            lineHeight: 1.29,
            scaleElement: 6,
            fontSize: 28,
        },
        small: {
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.headline,
            lineHeight: 1.33,
            scaleElement: 5,
            fontSize: 24,
        },
    },
    title: {
        large: {
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.title,
            lineHeight: 1.27,
            scaleElement: 4,
            fontSize: 22,
        },
        medium: {
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.title,
            lineHeight: 1.5,
            scaleElement: 1,
            letterSpacing: 0.15,
            fontSize: 16,
        },
        small: {
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.title,
            lineHeight: 1.43,
            scaleElement: 0,
            letterSpacing: 0.1,
            fontSize: 14,
        },
    },
    label: {
        large: {
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.label,
            lineHeight: 1.43,
            scaleElement: 0,
            letterSpacing: 0.1,
            fontSize: 14,
        },
        medium: {
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.label,
            lineHeight: 1.33,
            scaleElement: -1,
            letterSpacing: 0.5,
            fontSize: 12,
        },
        small: {
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.label,
            lineHeight: 1.45,
            scaleElement: -2,
            letterSpacing: 0.5,
            fontSize: 11,
        },
    },
    body: {
        large: {
            lineHeight: 1.5,
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.body,
            scaleElement: 1,
            letterSpacing: 0.5,
            fontSize: 16,
        },
        medium: {
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.body,
            lineHeight: 1.43,
            scaleElement: 0,
            letterSpacing: 0.25,
            fontSize: 14,
        },
        small: {
            fontWeight: VARIANT_FONT_WEIGHT_MAPPING.body,
            lineHeight: 1.33,
            scaleElement: -1,
            letterSpacing: 0.4,
            fontSize: 12,
        },
    },
};

export const getResponsiveTypographyStyles = (theme: Theme, variant: TypographyVariant, size: TypographySize) => {
    const { scaleElement } = styleConfig[variant][size];
    return getResponsiveFontSizeStyle(theme, scaleElement);
};

export function getTypographyStyles(variant: TypographyVariant, size: TypographySize) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { fontWeight, scaleElement: _scaleElement, ...rest } = styleConfig[variant][size];
    return { ...rest, ...getFontWeightStyle(fontWeight) };
}

function getTypographyStylesEntryForStyled(theme: Theme, variant: TypographyVariant) {
    return {
        [`&[data-variant="${variant}"]`]: {
            ...getTypographyStylesEntryForStyledAndSize(theme, variant, "large"),
            ...getTypographyStylesEntryForStyledAndSize(theme, variant, "medium"),
            ...getTypographyStylesEntryForStyledAndSize(theme, variant, "small"),
        },
    };
}

function getTypographyStylesEntryForStyledAndSize(theme: Theme, variant: TypographyVariant, size: TypographySize) {
    return {
        [`&[data-size="${size}"]`]: {
            ...getTypographyStyles(variant, size),
            '&[data-is-responsive="true"]': getResponsiveTypographyStyles(theme, variant, size),
        },
    };
}

export const TypographyBase = styled("div")(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
}));

const Wrapper = styled(TypographyBase)(({ theme }) => ({
    padding: 0,
    margin: 0,
    color: "black",

    "&[data-color='dark']": {
        color: "black",
    },
    "&[data-color='light']": {
        color: "white",
    },
    "&[data-color='subtle']": {
        color: theme.palette.text.secondary,
    },
    "&[data-color='primary']": {
        color: theme.palette.primary.main,
    },
    "&[data-color='secondary']": {
        color: theme.palette.secondary.main,
    },
    "&[data-color='success']": {
        color: theme.palette.success.main,
    },
    "&[data-color='error']": {
        color: theme.palette.error.main,
    },
    "&[data-color='warning']": {
        color: theme.palette.warning.main,
    },
    "&[data-color='info']": {
        color: theme.palette.info.main,
    },
    "&[data-line-clamp]": {
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: TYPOGRAPHY_LINE_CLAMP.reference,
        textOverflow: "ellipsis",
        overflow: "hidden",
    },

    '&[data-required="true"]': {
        "&:after": {
            content: '"*"',
            marginLeft: theme.spacing(0.5),
        },
    },

    ...getTypographyStylesEntryForStyled(theme, "display"),
    ...getTypographyStylesEntryForStyled(theme, "headline"),
    ...getTypographyStylesEntryForStyled(theme, "title"),
    ...getTypographyStylesEntryForStyled(theme, "label"),
    ...getTypographyStylesEntryForStyled(theme, "body"),
}));
