import { styled } from "@mui/material/styles";
import { HTMLAttributes, ReactNode, useState } from "react";

import { ProductPriceFragment } from "../apiHooks/graphql";
import { useProductTranslation } from "../hooks/useProductTranslation";
import { Price } from "../types/Price";
import { MinPriceV2 } from "./MinPriceV2";
import { PriceDataDialog } from "./Price";
import { Typography } from "./Typography";

type MarketPlaceMinPriceProps = {
    minPrice: Price | null;
    priceType?: "PER_PERSON" | "PER_GROUP";
    layout?: "horizontal" | "vertical";
    guidePricePricingData?: ProductPriceFragment["pricingData"];
} & HTMLAttributes<HTMLDivElement>;

export function MarketPlaceMinPrice({
    minPrice,
    priceType,
    layout = "horizontal",
    guidePricePricingData,
    ...props
}: MarketPlaceMinPriceProps) {
    const [t] = useProductTranslation();

    return (
        <MarketPlaceMinPriceContainer pricingData={guidePricePricingData}>
            <MarketPlaceMinPriceWrapper variant="body" data-layout={layout} component="div" {...props}>
                <MinPriceV2Styled price={minPrice} />
                {priceType === "PER_GROUP" && <PriceTypeWrapper>{t("label.perGroup")}</PriceTypeWrapper>}
            </MarketPlaceMinPriceWrapper>
        </MarketPlaceMinPriceContainer>
    );
}

const MarketPlaceMinPriceWrapper = styled(Typography)(({ theme }) => ({
    '&[data-layout="vertical"]': {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(0.5),
    },
    '&[data-layout="horizontal"]': {
        "> span:not(:last-child)": {
            marginRight: theme.spacing(0.5),
        },
    },
}));

const MinPriceV2Styled = styled(MinPriceV2)(({ theme }) => ({
    "& [data-ref='min-price-label']": {
        color: theme.palette.text.secondary,
    },
}));

const PriceTypeWrapper = styled("div")(({ theme }) => ({
    fontSize: 10,
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
}));

type MarketPlaceMinPriceContainerProps = {
    pricingData?: ProductPriceFragment["pricingData"];
    children?: ReactNode;
};

function MarketPlaceMinPriceContainer(props: MarketPlaceMinPriceContainerProps) {
    const { pricingData } = props;

    if (pricingData) {
        return <MarketPlaceMinPriceAdvanced pricingData={pricingData}>{props.children}</MarketPlaceMinPriceAdvanced>;
    }

    return props.children;
}

type MarketPlaceMinPriceAdvancedProps = {
    pricingData: ProductPriceFragment["pricingData"];
    children: ReactNode;
};
const MarketPlaceMinPriceAdvanced = ({ pricingData, children }: MarketPlaceMinPriceAdvancedProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDialogOpen(true);
    };

    const onClose = () => {
        setIsDialogOpen(false);
    };

    return (
        <>
            <Wrapper onClick={onClick}>{children}</Wrapper>
            <PriceDataDialog open={isDialogOpen} onClose={onClose} pricingData={pricingData} />
        </>
    );
};

const Wrapper = styled("div")(({ theme }) => ({
    cursor: "pointer",
    "&:hover": {
        backgroundColor: theme.palette.grey.A200,
        transition: theme.transitions.create("background-color", {
            duration: theme.transitions.duration.short,
        }),
        borderRadius: theme.shape.borderRadius,
    },
}));
