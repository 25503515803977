import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useNextTranslation, useLanguage } from "../hooks";
import { usePriceFormatter } from "../hooks/usePriceFormatter";
import { Price } from "../types/Price";

type MinPriceV2Props = {
    price: Price | null;
    size?: "small" | "normal";
} & BoxProps;

export const MinPriceV2 = ({ price, size = "normal", ...otherProps }: MinPriceV2Props) => {
    const [t] = useNextTranslation("general");

    return (
        <MinPriceV2Container
            data-testid="product-price-v2"
            display="flex"
            alignItems="baseline"
            gap={0.5}
            data-size={size}
            {...otherProps}
        >
            <span data-ref="min-price-label">{t("from").toLowerCase()}</span>
            <PriceV2 price={price} />
        </MinPriceV2Container>
    );
};

const PriceV2 = ({ price }: MinPriceV2Props) => {
    const language = useLanguage();
    const formatter = usePriceFormatter(price?.currency ?? "GBP", language);

    const numberFormatWithDecimals: Intl.NumberFormatPartTypes[] = [
        "integer",
        "group",
        "currency",
        "decimal",
        "fraction",
        "literal",
    ];

    return <PriceV2Wrapper>{formatter.toElements(price?.amount ?? 0, numberFormatWithDecimals)}</PriceV2Wrapper>;
};

const MinPriceV2Container = styled(Box)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    'span[data-ref="min-price-label"]': {
        fontSize: 12,
        lineHeight: 1,
    },
    '&[data-size="small"]': {
        fontSize: 14,
    },
    '&[data-size="normal"]': {
        fontSize: 16,
    },
}));

const PriceV2Wrapper = styled("div")({
    lineHeight: 1,
    display: "inline-block",

    '[data-type="literal"]': {
        maxWidth: 2,
        display: "inline-block",
    },

    '[data-type="decimal"]': {
        fontSize: "0.6em",
        display: "inline-block",
    },

    '[data-type="fraction"]': {
        fontSize: "0.6em",
        display: "inline-block",
    },

    '[data-type="currency"]': {
        fontSize: 12,
    },
});
